<template>
  <main class="sub">
    <div class="inner job-posting">
      <div class="tit req-type">
        <h1>근무내역</h1>
        <p class="stit">호구153에서 근무한 내역들을 확인하실 수 있습니다.</p>
      </div>
      <div class="sub-cont">
        <div class="myp-work-wrap">
          <div class="search-item-wrap">
            <div class="label">근무일자</div>
            <div class="date-item-wrap">
              <input type="text" class="inp datepicker" value="2021-12-01" />
              <span class="e01">-</span>
              <input type="text" class="inp datepicker" value="2021-12-01" />
            </div>
            <button type="button">검색</button>
          </div>
          <ul class="myp-work-list">
            <li v-for="(working, idx) in workingList" :key="`wk_${idx}`">
              <div class="date">{{ working.workingDate }}</div>
              <div class="work-item-wrap">
                <div class="wtit">{{ working.workingPlace }}</div>
                <div class="wcont">
                  <ul>
                    <li>
                      <div class="key">지급금액</div>
                      <div class="value">{{ working.payAmt }}원</div>
                    </li>
                    <li>
                      <div class="key">지급상태</div>
                      <div class="value">
                        <div class="status wait">
                          {{ working.workingStatus }}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <button type="button" v-if="working.userType === 'EMPLOYEE'">
                    지급요청
                  </button>
                </div>
              </div>
            </li>
            <li>근무 내역이 존재하지 않습니다.</li>
            <!--li>
              <div class="date">2021.07.14</div>
              <div class="work-item-wrap">
                <div class="wtit">힐튼호텔</div>
                <div class="wcont">
                  <ul>
                    <li>
                      <div class="key">지급금액</div>
                      <div class="value">130,000원</div>
                    </li>
                    <li>
                      <div class="key">지급상태</div>
                      <div class="value">
                        <div class="status succ">지급완료</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </!--li-->
          </ul>
          <!-- 페이징 추가 -->
          <div class="paging">
            <a href="#a" class="btn"><i class="el-icon-arrow-left"></i></a>
            <a href="#a" class="on">1</a>
            <a href="#a" class="">2</a>
            <a href="#a" class="">3</a>
            <a href="#a" class="btn"><i class="el-icon-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getEmployerWorkHistoryList } from 'Api/modules'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      workingStartDate: moment().format('YYYY-MM-DD'),
      workingEndDate: moment().format('YYYY-MM-DD'),
      workingList: [],
      page: 1,
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  async created() {
    // todo:: 달력 처리 필요
    const res = await getEmployerWorkHistoryList({
      userId: this.account.userId,
      workingStartDate: this.workingStartDate,
      workingEndDate: this.workingEndDate,
      page: this.page,
    })
    if (res) {
      this.workingList = res.content
      // todo:: paging ㅊㅓ리
    }
  },
}
</script>

<style></style>
